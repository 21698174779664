import { userStore } from '@context/userStore';
import '../styles/globals.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { classNames } from '@helpers/classNames';
import { EnvProvider } from '@helpers/envProvider';
import { getApplicationToken } from '@helpers/getApplicationToken';
import Plausible from '@helpers/plausible';
import { getUserSession } from '@providers/session';
import { usePlausible } from 'next-plausible';
import App from 'next/app';
import { Lato, Roboto } from 'next/font/google';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
config.autoAddCss = false;

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '300', '400', '700', '900'],
  variable: '--font-roboto',
});

const lato = Lato({
  subsets: ['latin'],
  weight: ['100', '300', '400', '700', '900'],
  variable: '--font-lato',
});

function Platform({ Component, pageProps, envs }) {
  const isLoggedIn = userStore((state) => state.isLoggedIn);
  const plausibleEvent = usePlausible();
  const environmentVariables = useState(envs);

  const { profileId, organizations, organizationId } = userStore();
  if (typeof window !== 'undefined' && isLoggedIn) {
    plausibleEvent('pageview', {
      props: { profileId: profileId, organizationId: organizationId, organizations: organizations },
    });
  }

  useEffect(() => {
    const fetchUserSession = async () => {
      await getUserSession();
    };
    fetchUserSession();
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#edb94a" />
        <meta name="msapplication-TileColor" content="#edb94a" />
        <meta name="theme-color" content="#edb94a" />
        {/* TODO https://plausible.io/docs/proxy/guides/nginx */}
      </Head>

      <EnvProvider envs={envs}>
        <Plausible environment={environmentVariables[0]['NEXT_PUBLIC_ENVIRONMENT']} />
        <div className={classNames(`${lato.variable} ${roboto.variable}`, 'overflow-x-hidden')}>
          <Component {...pageProps} />
        </div>
      </EnvProvider>
    </>
  );
}

Platform.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  const pageProps = await App.getInitialProps(appContext);

  // Fetch application_token on initial load, so that we can use it when calling subsequent api endpoints
  if (ctx.req && ctx.res) {
    await getApplicationToken(ctx.req, ctx.res);
  }

  // Read environment variables starting with NEXT_PUBLIC_
  const envs = Object.keys(process.env)
    .filter((key) => key.startsWith('NEXT_PUBLIC_'))
    .reduce((acc, key) => {
      acc[key] = process.env[key];
      return acc;
    }, {});

  return {
    ...pageProps,
    envs: envs,
  };
};

export default Platform;
